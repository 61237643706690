<template>
  <div>
    <ValidationProvider
      v-slot="{ errors }"
      name="forGameType"
      :rules="required?'required':''"
    >
      <b-form-group
        label-for="forGameType"
      >
        <b-select
          id="forGameType"
          v-model="gameType"
          :state="errors[0] ? false : null"
          :disabled="readonly"
        >
          <b-form-select-option value="">
            {{hasSelectAll?'ทุกประเภท': 'เลือกประเภท'}}
          </b-form-select-option>

          <b-form-select-option
            v-for="(forGameType, i) in forGameTypeOption"
            :key="i"
            :value="forGameType.value"
            :disabled="disabled"
          >{{ forGameType.text }} </b-form-select-option>
        </b-select>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  name: 'PromotionForGameTypeSelectInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    labelCols: {
      type: String,
      default: '3',
    },
    hasSelectAll: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gameType: '',
    }
  },
  computed: {
    forGameTypeOption() {
      return [
        { text: 'ทุกเกม', value: '0' },
        { text: 'สล็อต', value: '1' },
        { text: 'ยิงปลา', value: '2' },
        { text: 'คาสิโน', value: '3' },
      ]
    },
  },
  watch: {
    gameType(val) {
      this.$emit('update', val)
    },
    value(val) {
      if (val && !this.gameType) {
        this.gameType = val
      }
    },
  },
  created() {
    if (this.value) {
      this.gameType = this.value
    }
  },
  methods: {
  }
}
</script>
